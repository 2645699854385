<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <p>اعدادات الارسال (من الواتس الذكي)</p>
        <div class="form-group">
          <label for="">رقم الجوال لحسابك في الواتس الذكي</label>
          <input type="text"
            class="form-control" v-model="settings.smart_username">
        </div>
        <div class="form-group">
          <label for="">كلمة المرور لحسابك في الواتس الذكي</label>
          <input type="text"
            class="form-control" v-model="settings.smart_password">
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" v-model="settings.send_withdraw" value="yes">
            ارسال رسالة لولي الأمر عند سحب رصيد بواسطة ابنه
          </label>
        </div>
        <div class="form-group" v-if="settings.send_withdraw">
          <br>
          <textarea class="form-control" v-model="settings.withdraw_message" rows="3"></textarea>
        </div>
        <div class="col-12 text-center g" @click="save()">
            <button class="btn btn-primary">
                حفظ & تحديث البيانات
            </button>
        </div>
        <div class="col-12" v-if="error">
            <div class="alert alert-danger text-center g">
                <h4>
                    {{ error }}
                </h4>
            </div>
        </div>
        <div class="col-12" v-if="settings.device_title">
            <br>
            <div class="card card-body border" style="border: 2px green solid !important">
                الجهاز المتصل: {{ settings.device_title }} &mdash; {{ settings.device_phone }}
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            settings: {
                withdraw_message: `تم سحب {amount} ريال بواسطة ابنكم {name}
في الساعة {time}

الرصيد المتبقي: {left} ريال

#الرسالة تلقائية من نظام مصروفي.
يمكنكم متابعة الرصيد من الرابط التالي: 
{link}
اسم المدرسة: (اكتب هنا اسم المدرسة)`
            },
            error: ""
        }
    },
    created(){
        var g = this;
        $.post(api + '/user/general/settings', {
            jwt: this.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                if(r.response){
                    if(r.response.send_withdraw != 'yes' && r.response.send_withdraw != 'true'){
                        r.response.send_withdraw = false;
                    }
                    g.settings = r.response
                }
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
    },
    methods: {
        save(){
        var g = this;
        g.error = ""
        $.post(api + '/user/general/settings-save', {
            jwt: this.jwt,
            smart_username: this.settings?.smart_username,
            smart_password: this.settings?.smart_password,
            send_withdraw: this.settings?.send_withdraw,
            withdraw_message: this.settings?.withdraw_message,
        }).then(function(r){
            if(r.status != 100){
                g.error = r.response
            }else{
                location.reload()
            }
        }).fail(function(){
            g.error = "حدث مشكلة في الاتصال"
        })
        }
    },
}
</script>

<style>

</style>